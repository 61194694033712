/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es/reflect'; // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/**
 * Patch for Error Global Object, supports adding zone information to stack frame,
 * and also removing unrelated stack frames from zone.js internally
 */
import 'zone.js/plugins/zone-error';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// Fetch
import 'whatwg-fetch';

// Object.assign Polyfill IE 11
import 'mdn-polyfills/Array.from'; // 840 bytes
import 'mdn-polyfills/Object.assign'; // 330 bytes
import 'mdn-polyfills/String.prototype.startsWith'; // 145 bytes

// Pollyfill EndsWith
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position): boolean {
        const subjectString = this.toString();
        if (
            typeof position !== 'number' ||
            !isFinite(position) ||
            Math.floor(position) !== position ||
            position > subjectString.length
        ) {
            position = subjectString.length;
        }
        position -= searchString.length;
        const lastIndex = subjectString.lastIndexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

// Polyfill Array Find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate: any) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            const o = Object(this);
            const len = o.length >>> 0;
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            const thisArg = arguments[1];
            let k = 0;
            while (k < len) {
                const kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                k++;
            }
            return undefined;
        },
    });
}

// Polyfill the global for browserify libraries and running unit tests in Karma
window.global = window;
